import { JobStatusEnum } from '@gid/models';
import { JobTypeEnum } from '@gid/models/dist/entities/job-type-enum';
import { StatusRulesNamesEnum } from '@gid/models/dist/entities/status-rules-names-enum';

export default {
  data() {
    return {
      advancedFilterOptions: [
        {
          label: this.$i18n.t('filters.last_status_change_days'),
          type: 'last_status_change_days',
          component: 'b-form-input',
        },
        {
          label: this.$i18n.t('filters.created-date'),
          type: 'createddate',
          component: 'date-picker',
        },
        {
          label: this.$i18n.t('filters.installation-date'),
          type: 'appointment_date',
          component: 'from-to-filter',
        },
        {
          label: this.$i18n.t('filters.external-object-id'),
          type: 'eid',
          component: 'b-form-input',
        },
        {
          label: this.$i18n.t('filters.project-name'),
          type: 'project_id',
          component: 'b-form-input',
        },
        {
          label: this.$i18n.t('filters.opportunity'),
          type: 'opportunity',
          component: 'CrudMultiSelect',
          endpoint: '/data-api/opportunity',
          searchFields: ['id', 'name'],
          trackBy: 'id',
          selectLabel: 'name',
          multiple: true,
          initFetchLabels: true,
        },
        {
          label: this.$i18n.t('filters.job-type'),
          type: 'job_type',
          component: 'filter-multiselect',
          values: [
            { text: 'Precheck', value: JobTypeEnum.PRECHECK },
            { text: 'Installation', value: JobTypeEnum.INSTALLATION },
            { text: 'Activation', value: JobTypeEnum.ACTIVATION },
            { text: 'Hardware', value: 'hardware_sale' },
          ],
        },
        {
          label: this.$i18n.t('filters.service'),
          type: 'service',
          component: 'CrudMultiSelect',
          endpoint: '/data-api/service',
          searchFields: ['id', 'name'],
          trackBy: 'id',
          selectLabel: 'descriptionDe',
          customLabel: (item) => {
            return `${
              item.opportunity?.name ? `[${item.opportunity?.name}]` : ''
            } ${item.descriptionDe}`;
          },
          multiple: true,
          initFetchLabels: true,
        },
        {
          label: 'Kundennummer',
          type: 'customernumber',
          component: 'b-form-input',
        },
        {
          label: this.$i18n.t('filters.state'),
          type: 'state',
          component: 'filter-multiselect',
          values: [
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.MISSING_INVOICING_EID}.missing`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.MISSING_EID}.missing`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_PREWORK}.awaiting`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_NOT_REACHABLE}.unreachable`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.HOME_ELECTRICIAN_NOT_REACHABLE}.unreachable`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_WAITING_PRODUCT}.awaiting`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_WAITING_SUBSIDY}.awaiting`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_PAUSE_GENERIC}.paused`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PRODUCT_ORDERING}.pending-order`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PRODUCT_ORDERING}.pending-delivery`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.BRAND_PRODUCT_ORDERING}.waiting`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PARTNER_INFO}.awaiting`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PARTNER_PAUSE_GENERIC}.paused`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.BRAND_PAUSE_GENERIC}.paused`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PARTNER_ONBOARDING}.pending`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.ADMIN_VALIDATION}.pending`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_SIGNATURE}.missing-customer`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.PARTNER_QUOTE_VALIDATION}.pending-validation`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.NETWORK_OPERATOR}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.AGREEMENT_OWNER}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.CUSTOMER_PREWORK_BEFORE_ACCEPTED}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.CUSTOMER_INFO}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.CUSTOMER_INFO_GENERIC}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.BRAND_INFO_GENERIC}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.PARTNER_INFO_GENERIC}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.ADMIN_VALIDATION}.pending`,
          ].map((value) => ({
            text: this.$t(`state.${value}`),
            value,
          })),
        },
      ],
    };
  },
};
