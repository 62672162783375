<template>
  <div>
    <s class="text-muted mr-1" v-if="firstCancelationWithPriceChange">
      {{ firstCancelationWithPriceChange.old_price_selling | currency }}
    </s>
    {{ item.job.price_selling | currency }}
    <template v-if="firstCancelationWithPriceChange">
      <br />
      (
      {{ cancelationsWithPriceChange.length }}
      {{ $t('jobs.price-details.changes') }}
      <template v-if="lastCancelationWithPriceChange.created_date">
        , {{ $t('jobs.price-details.last') }}
        {{ lastCancelationWithPriceChange.created_date | moment('lll') }}
      </template>
      <template
        v-if="
          lastCancelationWithPriceChange.cancellation_reason_category ==
          'Storno'
        "
      >
        -
        <span v-if="lastCancelationWithPriceChange.id">
          {{ lastCancellationViewEntryWithPriceChange.description[locale] }}
        </span>
        <span v-else class="text-muted">
          ({{ $t('cancellation-deleted') }})
        </span>
      </template>
      )
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PriceHasChanged from '@gid/vue-common/components/mixins/PriceHasChanged.js';

export default {
  mixins: [PriceHasChanged],
  props: ['item', 'cancellations'],
  computed: {
    ...mapGetters(['locale']),
    lastCancellationViewEntryWithPriceChange() {
      return this.cancellations[
        this.lastCancelationWithPriceChange.cancellation_reason_opportunity_id
      ];
    },
  },
  methods: {
    getPriceChangeConfig() {
      return {
        job_view: this.item,
        price_selling: true,
      };
    },
  },
};
</script>
