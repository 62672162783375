<template>
  <div>
    <b-container fluid>
      <b-card bg-variant="light" class="my-3">
        <b-row>
          <b-col md="2">
            <b-form-group :label="$t('jobs.filter.filter') + ':'">
              <b-input-group>
                <b-form-input
                  v-e2e:filterSearch
                  v-model="filterSearch"
                  :placeholder="$t('jobs.filter.filter-placeholder')"
                />
                <b-input-group-append v-if="filterSearch">
                  <b-btn v-e2e:filterSearchButton @click="filterSearch = ''">
                    &times;
                  </b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="
                $t('jobs.filter.date-from') +
                ' - ' +
                $t('jobs.filter.date-to') +
                ':'
              "
            >
              <div class="d-flex">
                <date-picker v-e2e:filterDateFrom v-model="filterDateFrom" />
                <date-picker
                  :end="true"
                  v-e2e:filterDateTo
                  v-model="filterDateTo"
                  class="ml-2"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group :label="$t('jobs.filter.status') + ':'">
              <b-form-select
                v-e2e:filterStatusSelect
                v-model="filterStatus"
                :options="statusesGroupedByName"
              >
                <template slot="first">
                  <option :value="null">-- {{ $t('all') }} --</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="2" v-if="contactProfiles.includes('admin')">
            <b-form-group :label="$t('jobs.filter.project-manager') + ':'">
              <b-form-select
                v-model="filterProjectManager"
                :options="projectManagers"
              >
                <template slot="first">
                  <option :value="null">-- {{ $t('all') }} --</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group class="mb-0">
              <template slot="label">
                {{ $t('jobs.file-package.label') }}:
              </template>
              <b-btn
                id="download-report-btn"
                v-e2e:downloadReportButton
                block
                @click="downloadReport"
                :disabled="downloadDisabled"
              >
                <b-spinner
                  v-if="exportState == 'loading'"
                  class="gid-spinner--button mr-2"
                ></b-spinner>
                {{ $t('jobs.file-package.generate') }}
              </b-btn>
              <b-popover
                target="download-report-btn"
                placement="bottom"
                :variant="exportState"
              >
                <template #title>
                  {{ $t(`projects.file-package.action-${exportState}`) }}
                </template>
                {{
                  $t(
                    exportState == 'success'
                      ? 'jobs.file-package.explain'
                      : exportError,
                  )
                }}
              </b-popover>
              <small class="text-muted">
                {{ $t('jobs.file-package.count', { totalRows }) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-form-checkbox
                class="pt-1"
                v-e2e:filterUseAdvancedSearch
                v-model="filterUseAdvancedSearch"
              >
                {{ $t('filters.advanced.toggle') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <advanced-search
          v-if="filterUseAdvancedSearch"
          v-model="filterAdvancedSearch"
          :filter-options="advancedFilterOptions"
        />
      </b-card>
      <div class="overflow-auto">
        <b-table
          v-e2e:jobsTable
          show-empty
          striped
          stacked="md"
          :items="items"
          :busy="loading"
          :fields="computedFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDir"
          no-local-sorting
        >
          <template #cell(_brand_ids)="{ item }">
            <template v-if="item.job.affiliate_id">
              {{ item.job.affiliate_id }}
              <br />
              {{ item.job.import_id }}
              <br />
              {{ item.job.name }}
            </template>
            <span v-else>{{ item.job.name }}</span>
            <span v-if="item.customer.eid">/ {{ item.customer.eid }}</span>
            <a
              v-if="item.job.invoicing_eid"
              :href="`#edit-invoice-eid-${item.job.sfid}`"
              class="text-muted d-block text-break"
              @click.prevent="
                editEID(
                  item.job.sfid,
                  item.job.invoicing_eid,
                  InputTypes.INVOICING_EID,
                )
              "
            >
              {{ item.job.invoicing_eid }}
            </a>
            <RatingWidget context="job" v-bind="jobRatings[item.job.api_id]" />
          </template>
          <template #cell(job.status)="{ value }">
            {{ statusesById[value] }}
          </template>
          <template #cell(job._price)="{ item }">
            <price-details :item="item" :cancellations="cancellations" />
          </template>
          <template #cell(_actions)="{ item, toggleDetails }">
            <b-button size="sm" class="mr-1" @click.stop="toggleDetails">
              {{ $t('jobs.details') }}
            </b-button>
          </template>
          <template #cell(project_manager)="{ item }">
            <span
              v-if="
                !contactProfiles.includes('admin') && item.project_owners.length
              "
            >
              <span v-for="pm in item.project_owners" :key="pm">
                {{ `${pm.first_name} ${pm.last_name}` }}
              </span>
            </span>
            <span
              v-if="
                !contactProfiles.includes('admin') &&
                !item.project_owners.length
              "
            >
              None
            </span>
            <project-manager-dropdown
              :value="selectedProjectManager(item) || ''"
              :projectId="item.project.id"
              :setProjectManager="setProjectManager"
              :projectManagers="projectManagers"
              v-if="contactProfiles.includes('admin')"
            />
          </template>
          <template #row-details="{ item }">
            <job-details :item="item" @refresh="loadItems" />
          </template>
        </b-table>
      </div>
      <b-pagination
        v-e2e:jobsPagination
        :total-rows="totalRows"
        :per-page="perPage"
        v-model="currentPage"
        align="center"
      />
      <div v-if="currentPageCount" class="text-center text-muted">
        <small>{{ currentPageCount }} / {{ totalRows }}</small>
      </div>
      <b-modal ref="orderEidModal" :title="`Enter new eid`" @ok="saveOrderEID">
        <b-form-group :label="edit_eid_modal.label">
          <b-form-input v-model="edit_eid_modal.eid" />
        </b-form-group>
      </b-modal>
    </b-container>
    <div v-if="totalRows < 1" style="text-align: center">
      {{ $t('jobs.affiliate.cooperation-meeting') }}
      <br />
      <a
        :href="$t('jobs.affiliate.cooperation-meeting-link-url')"
        target="_blank"
      >
        {{ $t('jobs.affiliate.cooperation-meeting-link-text') }}
      </a>
    </div>
    <log-list ref="logView" :statuses="statuses" :context="'brand'" />
  </div>
</template>

<script>
import axios from 'axios';
import Api from '@gid/vue-common/api';
import { mapGetters } from 'vuex';
import { mapFilters } from '@gid/vue-common/store/utils';
import * as InputTypes from '@gid/vue-common/components/inputs/_types';
import { SET_REPORTS_FILTER } from '@gid/vue-common/store/reports.module';
import LogList from '@gid/vue-common/components/LogList';

import PriceDetails from './PriceDetails.vue';
import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import JobDetails from './JobDetails';
import ProjectManagerDropdown from '../components/ProjectManagerDropdown.vue';
import RatingWidget from '@gid/vue-common/components/RatingWidget.vue';
import AdvancedSearch from '@gid/vue-common/components/filters/AdvancedSearch.vue';
import AdvancedFilterOptionsMixin from '../mixins/AdvancedFilterOptionsMixin';
import { AccountProfileEnum } from '@gid/models';

export default {
  mixins: [AdvancedFilterOptionsMixin],
  components: {
    AdvancedSearch,
    DatePicker,
    JobDetails,
    LogList,
    PriceDetails,
    ProjectManagerDropdown,
    RatingWidget,
  },
  data() {
    return {
      fields: [
        { label: this.$t('jobs.table.heading.id'), key: '_brand_ids' },
        {
          label: this.$t('jobs.table.heading.project-id'),
          key: 'job.project_id',
        },
        {
          label: this.$t('jobs.table.heading.opportunity'),
          key: 'opportunity.name',
        },
        {
          label: this.$t('jobs.table.heading.order-value'),
          key: 'job._price',
        },
        {
          label: this.$t('jobs.table.heading.created-date'),
          key: 'job.created_date',
          sortable: true,
          formatter: (value) => (value ? this.$moment(value).format('L') : '-'),
        },
        {
          label: this.$t('jobs.table.heading.appointment'),
          key: 'job',
          formatter: (value) =>
            value.appointment_start_timestamp ? this.getTimeRange(value) : '-',
        },
        { label: this.$t('jobs.table.heading.status'), key: 'job.status' },
        {
          label: this.$t('jobs.table.heading.documentation'),
          key: '_actions',
          tdClass: 'text-nowrap',
        },
      ],
      items: [],
      currentPage: 1,
      currentPageCount: null,
      perPage: 10,
      totalRows: 0,
      sortBy: null,
      sortDir: false,
      loading: false,
      statuses: [],
      cancellations: {},
      exportState: 'available',
      exportError: null,
      currentJobIds: [],
      edit_eid_modal: {
        label: null,
        field: null,
        job_id: null,
        eid: null,
      },
      projectManagers: [],
      associatedWithProject: false,
      jobRatings: {},
      InputTypes,
    };
  },
  computed: {
    ...mapGetters(['locale', 'contactProfiles', 'user']),
    ...mapFilters({
      moduleName: 'reports',
      states: [
        'search',
        'dateFrom',
        'dateTo',
        'status',
        'projectManager',
        'useAdvancedSearch',
        'advancedSearch',
      ],
      setMutation: SET_REPORTS_FILTER,
      setCallback: 'filterUpdated',
    }),
    computedFields() {
      if (this.associatedWithProject) {
        return [
          { label: this.$t('jobs.table.heading.id'), key: '_brand_ids' },
          {
            label: this.$t('jobs.table.heading.project-id'),
            key: 'project.name',
          },
          {
            label: this.$t('jobs.table.heading.project_owner'),
            key: 'project_manager',
          },
          {
            label: this.$t('jobs.table.heading.order-value'),
            key: 'job._price',
          },
          {
            label: this.$t('jobs.table.heading.customer'),
            key: 'customer._fullname',
          },
          {
            label: this.$t('jobs.table.heading.created-date'),
            key: 'job.created_date',
            sortable: true,
            formatter: (value) =>
              value ? this.$moment(value).format('L') : '-',
          },
          {
            label: this.$t('jobs.table.heading.appointment'),
            key: 'job',
            formatter: (value) =>
              value.appointment_start_timestamp
                ? this.getTimeRange(value)
                : '-',
          },
          { label: this.$t('jobs.table.heading.status'), key: 'job.status' },
          {
            label: this.$t('jobs.table.heading.documentation'),
            key: '_actions',
            tdClass: 'text-nowrap',
          },
        ];
      }
      return [
        { label: this.$t('jobs.table.heading.id'), key: '_brand_ids' },
        {
          label: this.$t('jobs.table.heading.opportunity'),
          key: 'opportunity.name',
        },
        {
          label: this.$t('jobs.table.heading.order-value'),
          key: 'job._price',
        },
        {
          label: this.$t('jobs.table.heading.created-date'),
          key: 'job.created_date',
          sortable: true,
          formatter: (value) => (value ? this.$moment(value).format('L') : '-'),
        },
        { label: this.$t('jobs.table.heading.status'), key: 'job.status' },
        {
          label: this.$t('jobs.table.heading.documentation'),
          key: '_actions',
          tdClass: 'text-nowrap',
        },
      ];
    },
    downloadDisabled() {
      return this.totalRows == 0 || this.exportState != 'available';
    },
    statusesById() {
      return this.statuses.reduce((statuses, status) => {
        statuses[status.value] = status.text;
        return statuses;
      }, {});
    },
    statusesGroupedByName() {
      const byNames = this.statuses.reduce((statuses, status) => {
        if (!statuses[status.text]) statuses[status.text] = [];
        statuses[status.text].push(status.value);
        return statuses;
      }, {});
      return Object.keys(byNames).map((name) => ({
        text: name,
        value: byNames[name].join(','),
      }));
    },
    projectManagersGroupedByName() {
      const byNames = this.projectManagers.reduce((pms, pm) => {
        if (!pms[pm.name]) pms[pm.name] = [];
        pms[pm.name].push(pm.sfid);
        return pms;
      }, {});
      return Object.keys(byNames).map((name) => ({
        text: name,
        value: byNames[name].join(','),
      }));
    },
    canEditOrderEID() {
      return this.user?.account?.profiles.includes(
        AccountProfileEnum.EDIT_ORDER_EID,
      );
    },
    selectedProjectManager() {
      return (item) => {
        if (item && item.project_owners.length) {
          return item.project_owners.map((entry) => ({
            value: entry.sfid,
            text: `${entry.first_name} ${entry.last_name}`,
          }))[0];
        }
        return { value: '', text: '' };
      };
    },
  },
  watch: {
    currentPage() {
      this.loadItems();
    },
    sortBy() {
      this.loadItems();
    },
    sortDir() {
      this.loadItems();
    },
  },
  created() {
    this.loadItems();
    axios.get(`/api/brand/orders/statuses`).then((response) => {
      this.statuses = response.data.map((status) => ({
        text: status.brand_name[this.locale],
        value: status.job_status,
      }));
    });
    axios.get(`/api/brand/cancellation-reasons`).then((response) => {
      this.cancellations = response.data.reduce((cancellations, cr) => {
        cancellations[cr.id] = cr;
        return cancellations;
      }, {});
    });
  },
  methods: {
    searchQuery() {
      const searchQueryBase = {
        page_size: this.perPage,
        page_number: this.currentPage - 1,
        search: this.filterSearch.trim(),
        from_date: this.filterDateFrom,
        to_date: this.filterDateTo,
        status: this.filterStatus,
        project_manager: this.filterProjectManager,
        sort_by: this.sortBy ? this.sortBy : null,
        sort_dir: this.sortDir ? 'desc' : 'asc',
      };
      return this.filterUseAdvancedSearch && this.filterAdvancedSearch
        ? {
            ...searchQueryBase,
            search_json: JSON.stringify(this.filterAdvancedSearch),
          }
        : searchQueryBase;
    },
    getTimeRange(job) {
      return (
        this.$moment(job.appointment_start_timestamp).format('L H:mm') +
        ' - ' +
        this.$moment(job.appointment_end_timestamp).format('H:mm')
      );
    },
    async loadItems() {
      this.associatedWithProject = false;
      this.loading = true;
      this.items = await Api.get(`/api/brand/orders-affiliate`, {
        params: this.searchQuery(),
        autoCancelScope: this,
      })
        .then((response) => {
          this.totalRows = response.data.pagination.total_count;
          this.currentPageCount = response.data.data.length;
          this.currentJobIds = response.data.data.map(
            (jobView) => jobView.job.sfid,
          );

          response.data.data.forEach((job) => {
            if (job.project['id'] != null || job.project['name'] != null) {
              this.associatedWithProject = true;
            }
          });
          axios
            .post('/rating-api/averages/for-brand/job', {
              ids: [...response.data.data.map((entry) => entry.api_id)],
            })
            .then((res) => {
              this.jobRatings = res.data;
            })
            .catch(() => {
              // swallow error
            });

          return response.data.data;
        })
        .catch(() => [])
        .finally(() => {
          this.loading = false;
        });
    },
    filterUpdated() {
      this.currentPage = 1;
      this.exportState = 'available';
      this.$nextTick(() => this.loadItems());
    },
    jobHasDocs(job_view) {
      return Object.keys(job_view.files).length > 0;
    },
    async downloadReport() {
      this.exportState = 'loading';
      try {
        await axios.post(
          `/api/brand/orders-affiliate/export`,
          this.searchQuery(),
        );
        this.exportState = 'success';
      } catch (error) {
        this.exportState = 'danger';
        this.exportError = error.response.data;
      }
      this.$root.$emit('bv::show::popover', 'download-report-btn');
      setTimeout(() => {
        window.addEventListener('click', this.hidePopover);
      }, 100);
    },
    hidePopover() {
      this.$root.$emit('bv::hide::popover', 'download-report-btn');
      window.removeEventListener('click', this.hidePopover);
    },
  },
};
</script>

<style lang="scss">
.gid-info-icon {
  font-size: 1em;
}
</style>
